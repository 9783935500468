/* NX = not expanded */

.side-nav-container {
	background-color: var(--dark);
	width: 300px;
	height: 100vh;
	position: relative;
	color: var(--light);
	transition: 0.3s;

}
.side-nav-container-NX {
	width: 85px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */	
	display: grid;
}

.nav-heading {
	border-bottom: 1px white solid;
	margin-bottom: 40px;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 75px;
}

.nav-brand {
	font-family: XAquo-font-logo;
	padding-left: 25px;
	padding-top: 10px;
	display: flex;
	color: var(--light);
}
.nav-brand img {
	width: 20px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
}

.hamburger span {
	display: block;
	margin-top: 4px;
	background-color: var(--light);	
	height: 4px;
	width: 30px;
	transition: 0.8s;
	animation: changeColor 20s linear infinite;
}

.hamburger-in span:nth-child(1) {
	
	transform:translateY(8px)rotate(45deg);
}

.hamburger-in span:nth-child(3) {
	transform:translateY(-8px)rotate(-45deg);
}

.hamburger-out span:nth-child(1) {
	
	transform:translateY(8px)rotate(48deg);
}

.hamburger-out span:nth-child(3) {
	transform:translateY(-8px)rotate(-48deg);
}

.letter{
	width: 30px;
	padding: 0 20px;
	text-transform: none;
	font-family: XAquo-font-logo;	
}

@keyframes changeColor {
    0%, 100% {
      background: linear-gradient(45deg, #ffffff, #ffffff);
    }
    25% {
      background: linear-gradient(45deg, #09961f, #ffffff);
    }
    50% {
      background: linear-gradient(45deg, #09961f, #0091ff);
    }
    75% {
      background: linear-gradient(45deg, #0091ff, #ffffff);
    }
  }

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}

.hamburger-out {
	margin-left: 20px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(7, 1fr);	
}

.menu-item {	
    display: flex;
    color: var(--light);
    text-decoration: none;    
	border-radius: 10px;
	height: 50px;
	border-radius: 0px;
    border-left: 7px solid transparent; /* Use a transparent color initially */
    transition: border-left-color 0.3s ease; 
	margin-left: 5px;
}



.menu-item:hover {
	 border-left-color:  var(--primary);
}
.menu-item img {
	width: 30px;
	padding: 0 20px;
}

.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 16px;
	font-weight: 900;
	margin-top: 25px;
}

.nav-footer-user-position {
	font-size: 14px;
	margin-top: -15px;
	color: var(--gray);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: var(--primary);
}

