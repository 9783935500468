

.top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px; /* Optional: Set a max-width to control responsiveness */
    margin-bottom: 20px;
}

.top-row-small-boxes {
    display: flex;
    width: 50%;
    justify-content: space-between;    
}

.box {
    height: 400px;
    background-color: #181818;
    border-radius: 3px;
    border: 3px solid transparent; /* Initially transparent border */
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, border 0.3s ease-in-out;
}

.big-box:hover,
.box:hover{
    border: 3px #2a2727 solid;
    cursor: pointer;
}

.box-row-1-big{
    width: calc(50% - 10px);
}

.box-row-1-small{
    width: 100%;    
}

.top-row-small-boxes div:nth-child(2){
    margin-left: 3px;
    margin-right: -5px;
}

.bottom-row {
    width: 100%;
    max-width: 1200px; /* Optional: Set a max-width to control responsiveness */
}

.big-box {
    width: 100%;
    text-align: center;
    background-color: #181818;
    border-radius: 5px;
    border: 3px solid transparent; /* Initially transparent border */
    transition: height 0.3s ease-in-out, border 0.3s ease-in-out;
}

@media (max-width: 1200px) {
    .top-row    
    {
        flex-direction: column;
    }

    .box-row-1-big {
        width: 100%;
    }

     .box-row-1-small {      
       width: calc(50% - 10px);
       margin-right: 0px;       
    }
    
    .top-row-small-boxes{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px; 
        margin-top: 10px;
    }

    .bottom-row{
        flex-direction: column;
    }

    .big-box {        
        height: 400px; /* Adjust height for smaller screens */
        width: 100%;
    }
}

.box-title{        
    font-weight: bold;
    margin: 20px;
    padding-bottom: 10px;
    border-bottom: 1px rgb(42, 39, 39) solid;
}

.box-title.no-border{
    border-bottom: none;
    margin-bottom: 1px;
}

.box-sub-title{    
    font-size: 0.8rem;    
    padding-bottom: 10px;    
    margin-bottom: 10px;
}

.total-water-performance-container {
    width: 100%;
    display: flex;
    justify-content: space-between;        
}

.total-water-saving-performance {    
    width: 30%;
    padding-left: 20px;
    font-size: 1.6rem;
    font-weight: bold;
    color: #0091ff;
}

.total-water-saving-cash {    
    justify-content: space-evenly;
    padding-right: 20px;
    padding-top: 14px;
    width: 70%;
    display: flex;   
}
.other-performance-text{
    font-size: 0.8em;
}

.smaller-text {
    font-size: 0.6em; 
}

.second-section-box{
    margin-top: 40px;    
    padding: 20px;
    padding-bottom: 0px;
    border-top: 1px rgb(42, 39, 39) solid;
}


.general-info-single-value-container{
    display: flex;
    justify-content: space-between;
}

.general-info-text{
    font-size: 0.7em;
    color: #a6a6a6;
    margin-top: 5px;
}

.general-info-text-value{
    color: white;
    padding-right: 25px;
}
