.water-irrigation-chart-header{
    width: 100%;
    display: flex;
    border-bottom: 1px rgb(42, 39, 39) solid;
    height: 10%;
    justify-content: space-between;
}


.water-irrigation-chart-container{
    height: 90%;
}

.water-irrigation-chart-header-buttons-container{
    margin-right: 20px;
    margin-top: 15px;    
}

.water-irrigation-chart-header-button{    
    background-color: #181818;
    border: 1px rgb(42, 39, 39) solid;
    color: rgb(42, 39, 39);
    height: 20px;
    width: 100px;
    font-size: 0.7em;
}

.water-irrigation-chart-header-button:hover{
    border: 1px white solid;
    color: white;
    cursor: pointer;
}   

.water-irrigation-chart-header-buttons-container button:nth-child(1) {
	border: 1px white solid;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.water-irrigation-chart-header-buttons-container button:nth-child(2) {	
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}