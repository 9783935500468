@font-face {
    font-family: 'XAquo-font';
    src: url('./fonts/Reem_Kufi_Regular.ttf') format('truetype');
    font-weight: regular;
  }
  
  @font-face {
    font-family: 'XAquo-font-logo';
    src: url('./fonts/Abril Fatface Regular.ttf') format('truetype');
    font-weight: normal;
  }

:root {
	--primary: #0091ff;
	--dark: #0f3054;
	--gray: #756388;
	--light: #ffffff;
	--green: #09961f;
}
body {
	margin: 0;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0d0d0d;
	color: white;
}

code {
	font-family: "Inter", -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

.app-container {
	display: flex; /* Use flex display for side-by-side layout */
}

.main-container {
	padding: 10px;
	width: 100%;
	height: 100% !important;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;	    
}
