.title {
    font-size: 1.5rem;
    font-weight: bold;    
}

.tool-bar {
    margin: 20px;
    padding-bottom: 10px;
    border-bottom: 1px rgb(42, 39, 39) solid;
    display: flex;
    justify-content: space-between; /* Add this line */
}

.buttons-div{
    display: flex;    
    margin-right: 20px;
}
