.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(216 209 209 / 60%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: fadeIn 0.3s ease-in;
}


.close-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.close-button:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dialog-content {
    background-color: #070707;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 1200px; /* Set the maximum width */
    width: 50%; /* Set a relative width for responsiveness */
    max-height: 80vh; /* Set the maximum height to 80% of viewport height */
    overflow-y: auto; /* Add vertical scroll if content exceeds the height */
    margin: 0 auto; /* Center the dialog horizontally */
    position: relative; /* Add this line to position the title */
}

.dialog-title {        
    margin-top: 1%;
    font-size: 1rem;
    font-weight: bold;    
    text-align: left;
}

.dialog-sub-title {            
    font-size: 0.8rem;        
    text-align: left;
    color: #a6a6a6;
    font-weight: unset;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .dialog-content {
        max-width: 90%; /* Reduce the maximum width for smaller screens */
    }
    
    /* Adjust max-height for smaller screens */
    .dialog-content,
    .dialog-overlay {
        max-height: 90vh; /* Set the maximum height to 90% of viewport height */
    }
}


.create-field-content-container {
    margin-top: 2%;    
    text-align: center;
    display: flex;
}

.dialog-footer{
    display: flex;
    justify-content: space-between; /* Add this line */
}

.content-left-panel {
    flex: 1; 
    text-align: left;
}


.input-container {
    position: relative;
}

input {
    border: none;
    outline: none;
    background: transparent;
    padding: 5px;
    font-size: 16px;
    padding-top: 20px;
    color: white;
    width: 80%;
    border-bottom: 1px solid #000; /* Add a bottom border for the input */
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: white;
}

label {
    position: absolute;
    left: 5px;
    color: white;
    top: 0;
    
    transform: translateY(14px); /* Initially move the label down */
    transition: transform 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
    transform: translateY(0);
    font-size: 12px;
    color: white;    
}

.create-polygon-title{
    margin-top: 20%;    
}

.create-polygon-instruction{
    margin-top: 1%;
    font-size: 0.7rem;
    color: #a6a6a6;
}