.create-field-button {
    background-color: var(--primary);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;    
    font-size: 0.8em;    
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-left: 20px;
}

.create-field-button:hover {
    background-color: #0091ffd3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    transform: scale(1.1);
}

.plus-icon {
    margin-right: 8px;
    font-size: 1em;
    line-height: 1;
    transition: transform 0.3s;
}

.create-field-button:hover .plus-icon {
    transform: scale(1.2);
}

.crop-simulation-button {
    background-color: #134f1c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;    
    font-size: 0.8em;    
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    overflow: hidden;   
    margin-left:5px 
}
